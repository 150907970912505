import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, switchMap, of as observableOf } from 'rxjs';
import { API_URL_TOKEN } from 'src/app/shared/tokens';
import { UserService } from 'src/app/user/services/user.service';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthorizationInterceptor implements HttpInterceptor {
    constructor(
        @Inject(API_URL_TOKEN) private apiUrl: string,
        private authService: AuthService,
        private userService: UserService
    ) { }

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!req.url.startsWith(this.apiUrl)) {
            return next.handle(req);
        }

        return this.userService.currentUser.pipe(
            switchMap((currentUser) => {
                if (currentUser) {
                    return this.authService.isSessionValid().pipe(
                        switchMap((sessionValid) => {
                            if (!sessionValid) {
                                return this.authService.refreshSession();
                            }

                            return observableOf(true);
                        }),
                        switchMap(() => {
                            return this.authService.getIdToken().pipe(
                                switchMap((token) => {
                                    if (!token) {
                                        console.warn(`[Authorization] - No ID Token found!`);

                                        return next.handle(req);
                                    }

                                    else {
                                        const newReq = req.clone({
                                            headers: req.headers.set('Authorization', token),
                                        });

                                        return next.handle(newReq);
                                    }
                                }),
                            );
                        }),
                    );
                }

                return next.handle(req);
            })
        );
    }
}
