import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CookiesService } from 'src/app/shared/cookies/services/cookies.service';
import { API_URL_TOKEN } from 'src/app/shared/tokens';

@Injectable({
    providedIn: 'root'
})
export class IdentifierInterceptor implements HttpInterceptor {
    constructor(
        @Inject(API_URL_TOKEN) private apiUrl: string,
        private cookiesService: CookiesService
    ) { }

    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (!req.url.startsWith(this.apiUrl)) return next.handle(req);

        const newReq = req.clone({
            headers: req.headers.set('identifier', this.cookiesService.getCookie('userId')),
        });

        return next.handle(newReq);
    }
}
