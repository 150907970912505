import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth/services/auth.service';
import { Router } from '@angular/router';
import { catchError, Observable, of as observableOf, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { API_URL_TOKEN, APP_BASE_URL, APP_VERSION, GOOGLE_API_KEY, GOOGLE_MAPS_API_URL } from './shared/tokens';
import { UserModule } from './user/user.module';
import { SharedModule } from './shared/shared.module';
import { NavbarModule } from './navbar/navbar.module';
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthModule } from './auth/auth.module';
import { AlertsModule } from './alerts/alerts.module';
import { ApplicationIdInterceptor } from './auth/interceptors/application-id.interceptor';
import { AuthorizationInterceptor } from './auth/interceptors/authorization.interceptor';
import { EventsSearchModule } from './events/search/events-search.module';
import { CookiesService } from './shared/cookies/services/cookies.service';
import { v4 as uuidv4 } from 'uuid';
import { IdentifierInterceptor } from './auth/interceptors/identifier.interceptor';
import { AnalyticsModule } from './analytics/analytics.module';

export function startAuthService(
    authService: AuthService,
): () => Observable<boolean> {
    return () => authService.startAuth().pipe(
        catchError((err) => {
            console.error(`An error occurred while authenticating user - ${err}`);
            return observableOf(false);
        }),
        tap(() => {
            authService.redirectUser();
        })
    );
}

export function initializeUserIdentifier(
    cookieService: CookiesService
): () => Observable<boolean> {
    return () => observableOf(true).pipe(
        tap(() => {
            const existingId = cookieService.getCookie('userId');
            if (!existingId) {
                const userId = uuidv4();
                cookieService.setCookie('userId', userId);
            }
        })
    )
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AlertsModule,
        AnalyticsModule,
        AppRoutingModule,
        AuthModule,
        BrowserModule,
        EventsSearchModule,
        HttpClientModule,
        HttpClientJsonpModule,
        NavbarModule,
        SharedModule,
        UserModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: startAuthService,
            deps: [AuthService, Router],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeUserIdentifier,
            deps: [CookiesService],
            multi: true
        },
        {
            provide: APP_BASE_URL,
            useValue: environment.baseUrl,
        },
        {
            provide: API_URL_TOKEN,
            useValue: environment.apiUrl
        },
        {
            provide: APP_VERSION,
            useValue: environment.version
        },
        {
            provide: GOOGLE_MAPS_API_URL,
            useValue: environment.google.mapsAPIUrl
        },
        {
            provide: GOOGLE_API_KEY,
            useValue: environment.google.apiKey
        },
        {
            provide: 'Window',
            useValue: window
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApplicationIdInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: IdentifierInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
